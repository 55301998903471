import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/securing-your-asp-net-mvc-4-application",
  "date": "2013-04-25",
  "title": "SECURING YOUR ASP.NET MVC 4 APPLICATION",
  "author": "admin",
  "tags": ["development", "csharp", "asp.net"],
  "featuredImage": "feature.jpg",
  "excerpt": "Out of the Box when you create an MVC web site, anyone can post anything to your web site. So we need to control the user access."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Microsoft provides a pre-made code for securing your ASP.NET MVC 4 application: `}{`[Authorize]`}{` attribute Properties of the `}{`[Authorize]`}{` attribute`}</p>
    <ul>
      <li parentName="ul">{`users`}</li>
      <li parentName="ul">{`roles`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{` [HttpPost]
 [Authorize]
 public ActionResult Create([Bind(Exclude="CurrentPrice")]Models.Auction auction)
 {
    if (ModelState.IsValid)
    {
        // Save to the database
        var db = new AuctionsDataContext();
        db.Auctions.Add(auction);
        db.SaveChanges();
        return RedirectToAction("Index");
    } 

        return Create();
 }
`}</code></pre>
    <p>{`You could add `}<strong parentName="p">{`AllowAnonymous`}</strong>{` attribute to the controllers that can be accessed by anyone.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{` [AllowAnonymous]
 public class HomeController : Controller
 {
    public ActionResult Index()
    {
        return View();
    }
 }
`}</code></pre>
    <p>{`Now even though unauthorized users can see and fill the posts page, when they attempt to post, it will redirect to the log in page.
We can specify who could access the page by assigning a user to the Authorize attribute. (User)`}</p>
    <p>{`[Authorize(Users="John")]`}</p>
    <p>{`Also we could allow a group to access the create function. (Role)`}</p>
    <p>{`[Authorize(Roles="Admin")]`}</p>
    <p>{`Values as such are called a  Whitelist and opposite being Blacklist.`}</p>
    <h2>{`Creating User Accounts`}</h2>
    <p>{`Any user can create an account with your default ASP.NET MVC site by registering a user name and a password.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`<section class="social" id="socialLoginForm">
     ## Use another service to log <span class="Statement">in</span>.
     @Html.Action("ExternalLoginsList", 
         new { ReturnUrl = ViewBag.ReturnUrl })
</section>
`}</code></pre>
    <p>{`Also you could use external authentication services by uncommenting the fields of `}<inlineCode parentName="p">{`AuthConfig.cs`}</inlineCode>{` file in `}<inlineCode parentName="p">{`App_Start`}</inlineCode>{` folder.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      